<template>
  <div>
    <h1 class="text-base font-bold text-gray-type-2">Password</h1>
    <form class="w-full mt-8 lg:w-1/3" @submit.prevent="changePassword">
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="current-password"
          >Enter current password</label
        >
        <div class="relative mt-2">
          <input
            v-model="$v.form.current_password.$model"
            type="password"
            id="current-password"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
        </div>
        <span
          class="text-xs font-bold text-red-400"
          v-if="
            !$v.form.current_password.required &&
            $v.form.current_password.$error
          "
          >Please enter your current password</span
        >
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="new-password"
          >Enter new password</label
        >
        <div class="relative mt-2">
          <input
            v-model="$v.form.new_password.$model"
            type="password"
            id="new-password"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
        </div>
        <span
          class="text-xs font-bold text-red-400"
          v-if="!$v.form.new_password.required && $v.form.new_password.$error"
          >Please enter a password</span
        >
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="cofirm-password"
          >Confirm new password</label
        >
        <div class="relative mt-2">
          <input
            v-model="$v.form.confirm_new_password.$model"
            type="password"
            id="cofirm-password"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
        </div>
        <span
          class="text-xs font-bold text-red-400"
          v-if="!$v.form.confirm_new_password.sameAsPassword && $v.form.$error"
          >New passwords must be identical</span
        >
      </div>
      <button
        type="submit"
        class="block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none"
        :class="processing ? 'bg-gray-type-7' : 'bg-green-300'"
        :dislabed="processing"
      >
        {{ processing ? "Processing..." : "Change password" }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { required, sameAs, minLength } from "@vuelidate/validators";

export default {
  data() {
    return {
      form: {
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      },
      processing: false,
      errorMessage: "",
      http: null,
    };
  },
  validations() {
    return {
      form: {
        current_password: {
          required,
        },
        new_password: {
          required,
          minLength: minLength(6),
        },
        confirm_new_password: {
          sameAs: sameAs("new_password"),
        },
      },
    };
  },
  mounted() {
    const token = JSON.parse(localStorage.getItem("user")).token.token;

    this.http = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    changePassword() {
      this.$v.form.$touch();

      if (this.processing || this.$v.form.$error) {
        return;
      }

      this.processing = true;

      this.http
        .post("/login", {
          email: this.user.email,
          password: this.form.current_password,
        })
        .then(() => {
          this.http
            .patch(`/users/${this.user.id}`, {
              password: this.form.new_password,
            })
            .then(() => {
              this.$toast.success("Password changed successfully.");

              this.$nextTick(() => {
                this.$v.form.$reset();
              });
            })
            .catch(() => {
              this.$toast.error("Password change failed, please try again.");
            })
            .finally(() => (this.processing = false));
        })
        .catch((err) => {
          let message = "";

          if (err.response && err.response.status == 401) {
            message = "The current password you provided is invalid";
          }

          this.$toast.error(
            message || "Password change failed, please try again."
          );
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>